import {
  getGroupedAllocations,
  getMainLines,
  getMainContributors,
  getMainMovements,
  getNxPackTable,
  getManagers,
  getKeyPerformances,
  metadataSortCapi,
  getExposureByAssetClass,
  getExposureHistorical,
  getSectorIgb,
  getTableSurMesure,
  getMainLinesIsr,
  getExposureEvolution,
  getYearlyPerformanceLimit,
} from './new_utils'

export const transform = d => {
  const domainString = $root.$route.query.domain || $root.domain.join('|')

  const res = {}
  res.analyticsInput = d.businessData.share.analytics

  res.keyPerformances = getKeyPerformances(d.businessData.share.analytics, domainString)
  // template_name === 'factsheet_no_benchmark'
  res.keyPerformancesNoBench = getKeyPerformances(d.businessData.share.analytics, domainString, true)

  // no_benchmark_de
  // No analyticsInput, No keyPerformances

  res.comment = d.businessData.share.comment?.value ? d.businessData.share.comment?.value : ''
  res.managers = getManagers(d.businessData.share.characteristics.managers)
  res.orientation = d.businessData.share.characteristics.orientation

  res.riskIndicatorsTable = getNxPackTable(d.businessData.nxPackTables, 'risk_indicators')
  // template_name === 'factsheet_no_benchmark')
  res.riskIndicatorsTableNoBench = getNxPackTable(d.businessData.nxPackTables, 'risk_indicators', true)

  res.cumulatedPerformanceTable = getNxPackTable(
    d.businessData.nxPackTables,
    $root.share.mstar_1m ? 'cumulated_performance' : 'cumulated_performance_no_morning_star',
  )
  // 'factsheet_no_benchmark', 'factsheet_multiasset', 'factsheet_multiasset_de'].includes(res.template_name))
  res.cumulatedPerformanceTableNoBench = getNxPackTable(
    d.businessData.nxPackTables,
    $root.share.mstar_1m ? 'cumulated_performance' : 'cumulated_performance_no_morning_star',
    true,
  )

  res.shareCurrency = d.businessData.share.share_currency
  res.netAssetValue = d.businessData.share.analytics.netAssetValue
  res.srri = d.businessData.share.characteristics.srri
  res.mainMovements = getMainMovements(
    d.businessData.share.transactions.all,
    3,
    $root.share.characteristics?.achats,
    $root.share.characteristics?.ventes,
  )
  // lookthrough
  res.mainMovementsLookthrough = getMainMovements(
    d.businessData.share.transactions_lookthrough_no_filter?.all,
    3,
    $root.share.characteristics?.achats,
    $root.share.characteristics?.ventes,
  )
  // 'factsheet_taux_standard', 'factsheet_multiasset', 'factsheet_multiasset_de', 'factsheet_sur_mesure'
  res.mainMovementsNoFilter = getMainMovements(
    d.businessData.share.transactions_no_filter.all,
    3,
    $root.share.characteristics?.achats,
    $root.share.characteristics?.ventes,
  )

  res.mainContributors = getMainContributors(
    d.businessData.share.mainContributors,
    3,
    $root.share.characteristics?.contributeurs,
    $root.share.characteristics?.detracteurs,
  )
  res.benchmark_disclaimer = d.businessData.share.characteristics.benchmark_disclaimer

  res.mainLines = getMainLines(d.businessData.share.linesTop10)
  // lookthrough
  res.mainLinesLookthrough = getMainLines(d.businessData.share.lines_top10_lookthrough)
  // 'factsheet_taux_standard'
  res.mainLinesObligation = getMainLines(d.businessData.share.linesTop10Obligation)
  // 'factsheet_taux_standard_lookthrough'
  res.mainLinesObligationLookthrough = getMainLines(d.businessData.share.linesTop10ObligationLookthrough)
  // 'factsheet_multiasset', 'factsheet_multiasset_de'
  res.mainLinesTop5 = getMainLines(d.businessData.share.linesTop10)?.slice(0, 5)
  res.mainLinesObligationTop5 = getMainLines(d.businessData.share.linesTop10Obligation)?.slice(0, 5)
  // 'factsheet_isr', 'factsheet_isr_de'
  res.mainLinesIsr = getMainLinesIsr(d.businessData.share.linesTop10)

  // 'factsheet_sur_mesure'
  res.mainContributorsOnly = res.mainContributors.map(c => c.slice(0, 2))
  res.mainDetracteursOnly = res.mainContributors.map(c => c.slice(2))
  // No res.mainLines
  res.tableSurMesure = getTableSurMesure(d.breakdown.drilldown)

  res.getGroupedAllocations = field => {
    return getGroupedAllocations(d.businessData.share.allocation, field)
  }
  // 'factsheet_multiasset', 'factsheet_multiasset_de'
  res.getGroupedAllocationsRebased = field => {
    return getGroupedAllocations(d.businessData.share.allocation, field, d => d.axis.asset_class === 'Actions', true)
  }
  // lookthrough
  res.getGroupedAllocationsLookthrough = field => {
    return getGroupedAllocations(d.businessData.share.lookthrough, field)
  }

  res.metadataSortCapi = field => {
    return metadataSortCapi(d.businessData.share.allocation, field)
  }
  // lookthrough
  res.metadataSortCapiLookthrough = field => {
    return metadataSortCapi(d.businessData.share.lookthrough, field)
  }

  // 'factsheet_taux_standard'
  res.sectorIgbData = getSectorIgb($root.share.characteristics.secteurs_igb)

  // 'factsheet_sur_mesure'
  res.exposureEvolution = getExposureEvolution(d.breakdown.allocationHistory, ['AllocN1-Actions'])
  res.exposureByAssetClass = getExposureByAssetClass(d.breakdown.drilldown, ['AllocN1-Change_Fx'])
  res.exposureHistorical = getExposureHistorical(d.businessData.share.allocation)

  // 'factsheet_diversifie_dediee'
  res.meeschaert_theme =
    !!d.referential.share.otherFundCharacteristics.meeschaert_theme &&
    d.referential.share.otherFundCharacteristics.meeschaert_theme !== '0'

  res.yearlyPerformancesLimit = getYearlyPerformanceLimit(
    d.referential.share.otherFundCharacteristics?.pdfparamlimitnumberofyear,
  )

  return res
}

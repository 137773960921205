<style>
.bar {
  font-size: 12px;
  color: var(--text);
  --outer: 5px;
  --inner: 4px;
  --radius: 2px;
  margin: 8px 0;
}

.bar {
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-rows: auto 40px;
}

.exposure .bar .x-axis .tick div {
  transform: unset;
  text-overflow: unset;
}

.exposure {
  --primary: #ffde14;
}

.block-exposition_par_classe_actif .bar {
  grid-template-columns: 0px auto;
}

.block-exposition_par_classe_actif {
  margin-bottom: 20px;
}

.bar .y-axis {
  grid-area: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bar .x-axis {
  grid-area: 2 / 2;
  display: flex;
  justify-content: space-around;
  margin: 0 var(--outer);
}

.bar .y-axis .tick {
  height: 0;
}

.bar .y-axis .tick div {
  transform: translate(-10px, -50%);
  text-align: right;
}

.bar .gridlines {
  grid-area: 1 / 2;
  display: grid;
  opacity: 0.1;
}

.bar .gridlines .vertical {
  grid-area: 1 / 1;
  display: flex;
  justify-content: space-around;
}

.bar .gridlines .horizontal {
  grid-area: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bar .gridlines .horizontal > * {
  height: 1px;
  background: black;
}

.bar .gridlines .vertical > * {
  width: 1px;
  background: black;
}

.bar .content {
  grid-area: 1 / 2;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.bar .content > * {
  position: relative;
  display: flex;
  flex: 1;
  margin: 0 var(--outer);
}

.bar .content > * > * {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 var(--inner);
}

.bar .content > .positive > * {
  flex-direction: column-reverse;
}

.bar .content > * > * > * {
  position: relative;
}

.bar .content > * > * > * > * {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}

.bar.clustered .content .total {
  display: none;
}

.bar.clustered .content > * > * > * {
  position: absolute;
  width: calc(var(--thickness) - 2px);
}

.bar.clustered .content > * > * > * > * {
  display: flex;
  align-items: flex-start;
  transform: translate(0, -14px);
}

.bar.clustered .content > .negative > * > * > * {
  align-items: flex-end;
  transform: translate(0, 14px);
}

.bar.clustered .content > * > * > :not(:first-child) {
  left: calc(var(--thickness) * var(--n));
}

.bar .content .total {
  z-index: 1;
  position: absolute;
  margin: -2px;
}

.bar .content .total div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar:not(.horizontal) .content > * > * > * {
  height: var(--value);
}

.bar:not(.horizontal) .content .positive .total {
  bottom: var(--value);
  height: 2px;
  width: calc(100% + 4px);
}

.bar:not(.horizontal) .content .positive .total.cursor {
  background: var(--positive);
}

.bar:not(.horizontal) .content .positive .total div {
  bottom: var(--font-size);
}

.bar:not(.horizontal) .content .negative .total {
  top: var(--value);
  height: 2px;
  width: calc(100% + 4px);
}

.bar:not(.horizontal) .content .negative .total.cursor {
  background: var(--negative);
}

.bar:not(.horizontal) .content .negative .total div {
  top: var(--font-size);
}

.bar.horizontal .content > * > * > * {
  width: var(--value);
}

.bar.horizontal .content .rect {
  min-height: var(--font-size);
}

.bar.horizontal .content .positive .total {
  left: var(--value);
  width: 2px;
  height: calc(100% + 4px);
}

.bar.horizontal .content .positive .total.cursor {
  background: var(--positive);
}

.bar.horizontal .content .positive .total div {
  left: 5px;
  justify-content: flex-start;
}

.bar.horizontal .content .negative .total {
  right: var(--value);
  width: 2px;
  height: calc(100% + 4px);
}

.bar.horizontal .content .negative .total.cursor {
  background: var(--negative);
}

.bar.horizontal .content .negative .total div {
  right: 5px;
  justify-content: flex-end;
}

.bar.horizontal {
  grid-template-columns: 100px auto;
}

.bar.horizontal .y-axis {
  grid-area: 2 / 2;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.bar.horizontal .x-axis {
  grid-area: 1 / 1;
  flex-direction: column;
  justify-content: space-around;
  margin: var(--outer) 0;
}

.bar.horizontal .y-axis .tick div {
  transform: translate(-50%, 5px);
}

.bar.horizontal .y-axis .tick .positive {
  transform: translate(50%, 5px);
}

.bar.horizontal .x-axis .tick {
  flex: none;
}

.bar.horizontal .x-axis .tick div {
  transform: translate(-10px, -50%);
  text-align: right;
  white-space: nowrap;
}

.bar.horizontal .gridlines .vertical {
  flex-direction: column;
  justify-content: space-around;
}

.bar.horizontal .gridlines .horizontal {
  flex-direction: row;
  justify-content: space-between;
}

.bar.horizontal .gridlines .horizontal > * {
  height: unset;
  width: 1px;
}

.bar.horizontal .gridlines .vertical > * {
  height: 1px;
  width: unset;
}

.bar.horizontal .content {
  flex-direction: row-reverse;
}

.bar.horizontal .content > * {
  flex-direction: column;
  margin: var(--outer) 0;
}

.bar.horizontal .content > * > * {
  flex-direction: row-reverse;
  margin: var(--inner) 0;
}

.bar.horizontal .content > .positive > * {
  flex-direction: row;
}

.bar .gridlines .vertical {
  display: none;
}

.bar .content > .positive > * > :last-child {
  border-radius: var(--radius) var(--radius) 0 0;
}

.bar .content > .negative > * > :last-child {
  border-radius: 0 0 var(--radius) var(--radius);
}

.bar.horizontal .content > .positive > * > :last-child {
  border-radius: 0 var(--radius) var(--radius) 0;
}

.bar.horizontal .content > .negative > * > :last-child {
  border-radius: var(--radius) 0 0 var(--radius);
}

.pdf .bar :matches(.total, .rect, .tick) > div {
  font: var(--p3);
  font-weight: 200;
  font-size: var(--font-size);
  line-height: normal;
  color: #687d85;
  fill-opacity: 0.8;
}

[tt]:after {
  z-index: 10;
}

.bar .content .positive .rect.relative_allocation {
  background: var(--primary);
}
.bar .content .negative .rect.relative_allocation {
  background: var(--secondary);
}
.bar .content .rect.allocation_effect {
  background: var(--cat9);
}
.bar .content .rect.selection_effect {
  background: var(--cat10);
}
.bar:not(.horizontal) .content .positive .total {
  bottom: var(--value);
  height: 2px;
  width: calc(100% + 4px);
}
.bar:not(.horizontal) .content .positive .total.cursor {
  background: var(--positive);
}
.exposure .bar-block:first-child .rect {
  background: #ffde14;
}
.exposure .bar-block:nth-child(2) .rect {
  background: #f7ad00;
}
.exposure .bar-block:nth-child(3) .rect {
  background: #3fa535;
}
.exposure .bar-block:nth-child(4) .rect {
  background: #91c57e;
}
.exposure .bar-block:nth-child(5) .rect {
  background: #c2b7b3;
}
</style>
<template>
  <div class="bar" :class="options && options.variant">
    <div class="content">
      <div class="positive">
        <div class="bar-block" v-for="(bar, barIndex) in bars.map(bar => bar.filter((v, k) => k !== 'k'))">
          <div
            class="total"
            :style="{
              '--value': (bar.v().sum() / axes.y[0]) * 100 + '%',
              '--thickness': 100 / bar.v().length + '%',
            }"
            :class="{ cursor: bar.v().length > 1 }"
            v-if="bar.v().sum() > 0"
          >
            <div>
              {{ window.format(format_)(bar.v().sum()).replace('%', '') }}
            </div>
          </div>
          <div
            class="rect"
            :class="name"
            :style="{
              '--value': (cell / axes.y[0]) * 100 + '%',
              '--thickness': 100 / bar.v().length + '%',
              '--n': n,
            }"
            :tt="'+' + window.format(format_)(cell).replace('%', '')"
            v-show="cell > 0"
            v-for="(cell, name, n) in bar"
          >
            <div>{{ '+' + window.format(format_)(cell).replace('%', '') }}</div>
          </div>
        </div>
      </div>
      <div class="negative" v-if="options && options.variant !== 'positive'">
        <div v-for="bar in bars.map(bar => bar.filter((v, k) => k !== 'k'))">
          <div
            class="total"
            :style="{
              '--value': (-bar.v().sum() / axes.y[0]) * 100 + '%',
              '--thickness': 100 / bar.v().length + '%',
            }"
            :class="{ cursor: bar.v().length > 1 }"
            v-if="bar.v().sum() < 0"
          >
            <div>
              {{ window.format(format_)(bar.v().sum()).replace('%', '') }}
            </div>
          </div>
          <div
            class="rect"
            :class="name"
            :style="{
              '--value': (-cell / axes.y[0]) * 100 + '%',
              '--thickness': 100 / bar.v().length + '%',
              '--n': n,
            }"
            :tt="window.format(format_)(cell).replace('%', '')"
            v-show="cell < 0"
            v-for="(cell, name, n) in bar"
          >
            <div>{{ window.format(format_)(cell).replace('%', '') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="gridlines">
      <div class="horizontal" v-if="options && options.variant !== 'positive'">
        <div v-for="i in axes.y"></div>
      </div>
      <div class="vertical">
        <div v-for="bar in bars"></div>
      </div>
    </div>
    <div class="y-axis" v-if="options && options.variant !== 'positive'">
      <div class="tick" v-for="i in axes.y">
        <div>{{ window.format(format_y)(i) }}</div>
      </div>
    </div>
    <div class="x-axis">
      <div class="tick" v-for="bar in bars">
        <div>{{ t[bar.k] || bar.k }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data', 'options'],
  computed: {
    axes() {
      const max =
        this.options && this.options.variant === 'clustered'
          ? this.bars
              .map(d =>
                d
                  .filter((_, k) => k !== 'k')
                  .map(d => Math.abs(d))
                  .v()
                  .max(),
              )
              .max()
          : this.bars
              .map(d =>
                d
                  .filter((_, k) => k !== 'k')
                  .filter(Number)
                  .reduce(
                    (acc, v) => {
                      v > 0 ? (acc['positive'] += v) : (acc['negative'] += -v)
                      return acc
                    },
                    { positive: 0, negative: 0 },
                  )
                  .v()
                  .max(),
              )
              .max()
      const power = Math.round(Math.log10(max))
      const base100 = max / Math.pow(10, power - 1)
      const smooth = Math.ceil(base100 / 5) * 5 * Math.pow(10, power - 1)
      return {
        x: this.bars.map('k'),
        y: [smooth, smooth / 2, 0, -smooth / 2, -smooth],
      }
    },
    bars() {
      let { filter, group, sort, max } = {
        filter: b => b.v().sum() !== 0,
        group: 'k',
        sort: b => -b.filter(Number).v().sum(),
        ...this.options,
      }
      const xy = this.data
        .reduce((acc, v, k) => {
          acc[k] = typeof v !== 'object' ? { [k]: v } : v
          return acc
        }, {})
        //.filter(filter)
        .map((v, k) => ({ ...v, k }))
        .v()
        .group(group)
        .v()
        .flat()
        .sort(sort)
      if (max) {
        max = Math.min(max, this.data.keys().length)
        const abs_sort = b => -Math.abs(b.filter(Number).v().sum())
        const not_other = xy
          .sort(abs_sort)
          .slice(0, max - 1)
          .flat()
          .map('k')
        group = d => (not_other.includes(d.k) ? d.k : 'other')
      }
      return xy
        .group(group)
        .map(g => g.reduce((acc, e) => (e.map((v, k) => (acc[k] = (acc[k] || 0) + v || 0)), acc), {}))
        .map((v, k) => ({ ...v, k }))
        .v()
        .flat()
        .sort(x => (x.k === 'other' ? 1 : 0))
    },
    format_y() {
      if (this.options && this.options.format && ['.', ','].find(this.options.format.charAt(0)))
        return '.0' + this.options.format.slice(-1)
      return (this.options && this.options.format) || '.0%'
    },
    format_() {
      if (this.options && this.options.format) return this.options.format
      const defaultf = this.axes.y.map(d => Math.abs(d)).median() < 0.005 ? 'bp' : '.1%'
      if (
        this.$root.query.metric === 'diff' ||
        (this.$root.query.evolution && this.$root.config.datasets[this.$root.screen.datasets[0]].period === 'daily')
      )
        return '+' + defaultf
      return defaultf
    },
  },
}
</script>

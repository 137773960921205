import { getGqlQuery } from './new_utils'

export const getFactsheetQuery = async (fundId, isinShare, domainString, domainAllocation, lang, inceptionDomain) => {
  const query = `
  query FactsheetQuery(
    $fundId: FuidInput!
    $fuid: FuidInput!
    $isinShare: String!
    $domain: Domain!
    $lang: Lang!
    $domainAllocation:  Domain!
    $inceptionDomain:  Domain!
  ) {
    businessData {
      
      nxPackTables {
        table
        name
      }
      share(shareId: $isinShare) {
        mainContributors(domain: $domain){
          fuid
          name
          contribution
        }
        lines_top10_lookthrough(domain: $domain, lookthroughLevel: 4) {
          name
          weight_fund
          dimension0
          dimension1
          dimension2
        }
        linesTop10: lines_top10(domain: $domain) {
          name
          weight_fund
          dimension0
          dimension1
          dimension2
        }
        linesTop10Obligation: lines_top10_obligation(domain: $domain) {
          dimension0
          dimension1
          dimension2
          name
          weight_fund
        }
        linesTop10ObligationLookthrough: lines_top10_obligation(domain: $domain, lookthroughLevel: 4) {
          dimension0
          dimension1
          dimension2
          name
          weight_fund
        }
          
        fund_inception
        share_inception
        transactions_lookthrough_no_filter: transactions_lookthrough(domain: $domain, movementsFilter: "") {
          all {
            name
            type
            var
            id
          }
        }
        transactions_no_filter: transactions(domain: $domain, movementsFilter: "") {
          all {
            name
            type
            var
            id
          }
        }
        transactions_lookthrough(domain: $domain, movementsFilter: "transaction_count_filter_no_opcvm") {
          all {
            name
            type
            var
            id
          }
        }
        transactions(domain: $domain, movementsFilter: "transaction_count_filter_no_opcvm"){
          all {
            name
            type
            var
            id
          }
        }
        lookthrough(domain: $domain) {
          date
          fundId
          shareId
          name
          asset_class
          asset_currency
          quantity
          price
          fixing
          valuation
          fuid
          asof
          date_d_echeance
          date_d_echeance_with_call
          weight_fund
          weight_benchmark
          weight_diff_benchmark
          weight_active_share_benchmark
          axis
          exposure_fund
          contrib_sensi_fund
          contrib_ytm_fund
          contrib_deltaaction_fund
          contrib_spreadobl_fund
          exposure_benchmark
          contrib_sensi_benchmark
          contrib_ytm_benchmark
          contrib_deltaaction_benchmark
          contrib_spreadobl_benchmark
        }
        allocation(domain: $domainAllocation) {
          date
          fundId
          shareId
          name
          asset_class
          asset_currency
          quantity
          price
          fixing
          valuation
          fuid
          asof
          date_d_echeance
          date_d_echeance_with_call
          weight_fund
          weight_benchmark
          weight_diff_benchmark
          weight_active_share_benchmark
          axis
          exposure_fund
          contrib_sensi_fund
          contrib_ytm_fund
          contrib_deltaaction_fund
          contrib_spreadobl_fund
          exposure_benchmark
          contrib_sensi_benchmark
          contrib_ytm_benchmark
          contrib_deltaaction_benchmark
          contrib_spreadobl_benchmark
        }
        characteristics {
          capi_moyenne
          srri
          benchmark_disclaimer
          share_letter
          fund_type
          pea_eligible
          management_company
          managers
          amf_classification
          orientation
          bloomberg_code
          settlement
          minimum_initial_subscription
          initial_charge
          redemption_fee
          management_fee
          performance_fee
          custodian
          fund_admin
          assignment_distributable_sums
          valuation_frequency
          pdfparamlimitnumberofyear
          category
        }
        slug
        benchmark {
          id
          label
        }
        share_currency
        analytics {
          domainHistorical {
            date
            fund
            benchmark
            benchmark2
            benchmark3
          }
          yearlyPerformances(domain: $domain) {
            year
            fund
            benchmark
            benchmark2
            benchmark3
            month
            year
            diff
            risk_free
            real_nav
            aum_fund_in_fund_ccy
          }
          monthlyPerformances(domain: $domain) {
            date
            fund
            benchmark
            benchmark2
            benchmark3
            month
            year
            diff
            risk_free
            real_nav
            aum_fund_in_fund_ccy
          }
          domainPerformance {
            date
            fund
            benchmark
            benchmark2
            benchmark3
            month
            year
            diff
            risk_free
            real_nav
            aum_fund_in_fund_ccy
          }
          netAssetValue
          drawdownDetails {
            drawdown
            begin
            begin_nav
            end
            end_nav
            recovery
            duration
          }
          aum(domain: $domain){
            aum_fund_in_fund_ccy
            aum_share_in_fund_ccy
            date
            shareId
          }
          sharpe_ratio1y: domainSharpeRatio(domain: $domain, period: "1y")
          sharpe_ratio3y: domainSharpeRatio(domain: $domain, period: "3y")
          information_ratio1y: informationRatio(domain: $domain,period: "1y")
          information_ratio3y: informationRatio(domain: $domain, period: "3y")
          tracking_error1y: trackingError(domain: $domain,period: "1y")
          tracking_error3y: trackingError(domain: $domain,period: "3y")
          volatility1y: volatility(domain: $domain, period: "1y") {
            fund
            benchmark
          }
          volatility3y: volatility(domain: $domain, period: "3y") {
            fund
            benchmark
          }
        }
  
        comment(domain: $domain, lang: $lang) {
          period
          date
          value
        }
  
        asof
        axis_of_analysis
        contribution(domain: $domain) {
          fuid
          name
          contribution_fund
        }
      }
    }
    referential {
      share(fuid: $fuid) {
        otherFundCharacteristics
      }
    }
    breakdown {
    drilldown(fuid: $fundId, domain: $domainAllocation, dimensions: ["ASSET_GROUP", "ASSET_DETAIL"], metric: "exposure", options: { storedSource: "MANDARINE", drilldownMode: nested, order: DESC, orderBy: "name", customLabelOrder: ["ASSET_GROUP_DETAIL_COMBINED"]})
    allocationHistory(fuid: $fundId, domain: $inceptionDomain, groupBy: "ASSET_GROUP", metric: "exposure", options: { storedSource: "MANDARINE" }) {
        date
        values {
          key
          value
        }
      }
    }
  }`

  const qVariables = {
    fundId,
    isinShare,
    domain: domainString,
    lang: lang || 'fr',
    fuid: isinShare,
    domainAllocation,
    inceptionDomain: inceptionDomain || domainAllocation,
  }
  const res = await getGqlQuery(query, qVariables) // TODO: run the query here?
  return res
}

export const getLastAllocationDate = async (isinShare, dateString) => {
  const q = `
  query lastAllocationDateQuery($isinShare: String!) {
    businessData {
      share(shareId: $isinShare) {
        allocationDates
      }
    }
  }
  `
  const qVariables = {
    isinShare,
  }

  const res = await getGqlQuery(q, qVariables)
  const allocationDates = res.businessData.share.allocationDates.slice().sort((a, b) => (b > a ? 1 : b < a ? -1 : 0))
  if (dateString.length === 7) {
    // Case 1: dateString is "YYYY-MM"
    const monthStart = dateString + '-01'
    const nextMonth = ((parseInt(dateString.split('-')[1], 10) % 12) + 1).toString().padStart(2, '0')
    const year = nextMonth === '01' ? (parseInt(dateString.split('-')[0], 10) + 1).toString() : dateString.split('-')[0]
    const monthEnd = year + '-' + nextMonth + '-01'

    for (const date of allocationDates) {
      if (date >= monthStart && date < monthEnd) {
        return date
      }
    }
  } else if (dateString.includes('|')) {
    // Check if there's a '|' in the dateString
    const [startDate, endDate] = dateString.split('|')

    if (startDate.length === 7 && endDate.length === 7) {
      // Case 4: dateString is "YYYY-MM|YYYY-MM"
      const lastDay = getLastDayOfMonth(parseInt(endDate.split('-')[0], 10), parseInt(endDate.split('-')[1], 10) - 1)

      for (const date of allocationDates) {
        if (date <= lastDay) {
          return date
        }
      }
    } else {
      // Case 3: dateString is "YYYY-MM-DD|YYYY-MM-DD"
      for (const date of allocationDates) {
        if (date <= endDate) {
          return date
        }
      }
    }
  } else {
    // Case 2: dateString is "YYYY-MM-DD"
    for (const date of allocationDates) {
      if (date <= dateString) {
        return date
      }
    }
  }

  return null
}

export const getTranslations = async lang => {
  const q = `query TranslationsQuery($lang: Lang!) {
    businessData{
      translation(lang: $lang)
    }
  }`
  const qVariables = {
    lang,
  }
  const res = await getGqlQuery(q, qVariables)
  return res.businessData.translation
}

<script>
export const additions = { theme: '.pdf-page' }

import mixin1 from '../../mixins/global.js'
import mixin2 from '../../mixins/info.js'
import mixins from '../../mixins/old-mixins.js'
import mixin9 from '../../mixins/factsheet.js'
import {
  getLastAllocationDate,
  getBestContributors,
  getWorstContributors,
  getBestContributorsWeightDiff,
  getWorstContributorsWeightDiff,
  getGqlQuery,
  getFactsheetQuery,
} from '../../new_utils'
import { useAttribution } from '@100-m/hauru/src/applications/asset-management/composables/useAttribution.js'
import { useContribution } from '@100-m/hauru/src/applications/asset-management/composables/useContribution.js'

const { getShareInfo, getAttribution, getAttributionHistoryData, getExcessReturn } = useAttribution()
const { getContribution } = useContribution()

const enrichArrayA = (arrayA, arrayB) => {
  return arrayA.map(itemA => {
    const itemB = arrayB.find(b => b.fuid === itemA.fuid)
    return itemB
      ? {
          ...itemA,
          weight_benchmark: itemB.weight_benchmark,
          weight_fund: itemB.weight_fund,
          weight_diff: itemB.weight_fund - itemB.weight_benchmark,
        }
      : itemA
  })
}

export default {
  mixins: [mixin1, mixin2, mixins, mixin9],
  async mounted() {
    $root.refresh = $root.refresh + 1
    const isinShare = this.$root.$route.fullPath.split('/')[2].split('-')[1]
    const domainString = this.$root.$route.query.domain || $root.domain.join('|')
    this.domain = domainString.split('|')

    // Get the share info & its axes of analysis:
    const params = {
      fuid: isinShare,
      isinShare,
      shareId: isinShare,
      domain: $root.$route.query.domain || $root.domain.join('|'),
      asof: $root.domain[1],
      lang: $root.$route.query.lang || $root.lang,
    }

    const shareData = await getShareInfo(params)
    this.excessReturnData = await getExcessReturn(params)
    this.availableDimensions = shareData.axis_of_analysis

    const allocationQuery = `query AllocationQuery(
  $isinShare: String!
  $domain: Domain!
) {
  businessData {
    share(shareId: $isinShare) {
      allocation(domain: $domain) {
        weight_fund
        weight_benchmark
        fuid
      }
    }
  }
}
    `
    // Get the performance data of the share:
    const query = `query PerfQuery(
      $isinShare: String!
      $domain: Domain!
    ) {
      businessData {
        share(shareId: $isinShare) {
          analytics {
            domainHistorical(domain: $domain) {
              date
              fund
              benchmark
            }
          }
        }
      }
    }`
    const lang = this.$root.lang
    const fundId = $root.userflow.fundId
    const d = await getFactsheetQuery(fundId, isinShare, domainString, lang)
    const res = await getGqlQuery(query, { isinShare, domain: domainString })
    const resAllocation = await getGqlQuery(allocationQuery, {
      isinShare,
      domain: await getLastAllocationDate(isinShare, domainString),
    })
    const weightData = resAllocation?.businessData?.share?.allocation

    const weightedFundContributors = enrichArrayA(d.businessData.share.mainContributors, weightData)
    const weightedAllContributors = enrichArrayA(d.businessData.share.contribution, weightData)
    this.bestContributors = getBestContributors(
      weightedFundContributors,
      5,
      this.$root.share.characteristics?.contributeurs,
      ['name', 'contribution', 'weight_fund'],
      true,
    )
    this.worstContributors = getWorstContributors(
      weightedFundContributors,
      5,
      this.$root.share.characteristics?.detracteurs,
      ['name', 'contribution', 'weight_fund'],
      true,
    )
    this.bestContributorsWeightDiff = getBestContributorsWeightDiff(weightedAllContributors, 5, [
      'name',
      'active_weight',
      'contribution',
    ])
    this.worstContributorsWeightDiff = getWorstContributorsWeightDiff(weightedAllContributors, 5, [
      'name',
      'active_weight',
      'contribution',
    ])

    this.performanceData = res.businessData.share.analytics.domainHistorical
    // Get the allocation data:
    this.allocationData = d.businessData.share.allocation //changed

    // Get the contribution data:
    this.contributionData = []
    this.contributionData = enrichArrayA(await getContribution(params), weightData)

    // Get the attribution data for each axis of analysis:
    this.attributionData = []
    this.effectData = {}

    await Promise.all(
      this.availableDimensions.map(async dimension => {
        const queryParams = { ...params, dimension }
        const data = await getAttribution(queryParams)
        console.log(`Received attribution data for the "${dimension}" axis.`)
        this.attributionData.push({ dimension, data })

        // Build the "effect data" along with their respective KPIs:
        this.effectData[dimension] = {}
        Object.entries(data).forEach(([dimValue, dimData]) => {
          const effects = Object.entries(dimData)
            .filter(([key]) => key.endsWith('_effect'))
            .reduce((acc, [key, value]) => {
              acc[key] = value
              return acc
            }, {})
          this.effectData[dimension][dimValue] = effects
        })
      }),
    )
    // console.log('attributionData', this.attributionData)
    // console.log('effectData', this.effectData)

    this.allAtttributionHistory = {}
    await Promise.all(
      this.availableDimensions.map(async dimension => {
        const queryParams = { ...params, dimension }
        const data = await getAttributionHistoryData(queryParams)
        this.allAtttributionHistory[dimension] = data
      }),
    )
    this.loaded = true
  },

  data() {
    return {
      loaded: false,
      domain: null,
      availableDimensions: [],
      performanceData: null,
      allocationData: null,
      contributionData: null,
      attributionData: null,
      attributionHistoryData: null,
      effectData: null,
      excessReturnData: null,
      bestContributors: null,
      worstContributors: null,
      allAtttributionHistory: null,
    }
  },

  computed: {
    contributors() {
      const vl0 = this.performanceData[0].fund
      const ret = Object.entries(
        this.contributionData.filter(d => d.fuid !== 'UNEXPLAINED' && !d.fuid.startsWith('CASH')).group('fuid'),
      )
        .reduce((acc, [fuid, data]) => {
          acc.push([
            data[0].name || fuid,
            data.sum('pnlOrFeesPerShare_fund') / vl0,
            data.last().date === this.performanceData.last().date ? data.last().weight_fund : 0,
          ])
          return acc
        }, [])
        .sort(d => d[1], true)
        .map(d => [d[0], window.format('.2%')(d[1]), window.format('.2%')(d[2])])
      return ret
    },
    relative_weights() {
      const vl0 = this.performanceData[0].fund
      return Object.entries(
        this.contributionData.filter(d => d.fuid !== 'UNEXPLAINED' && !d.fuid.startsWith('CASH')).group('fuid'),
      )
        .reduce((acc, [fuid, data]) => {
          acc.push([
            data[0].name || fuid,
            data.last().date === this.performanceData.last().date
              ? data.last().weight_fund - data.last().weight_benchmark
              : 0,
            data.sum('pnlOrFeesPerShare_fund') / vl0,
          ])
          return acc
        }, [])
        .sort(d => d[1], true)
        .map(d => [d[0], window.format('.2%')(d[1]), window.format('.2%')(d[2])])
    },
    top_single_attribution() {
      const dataArray = Object.entries(this.attributionData.find(d => d.dimension === 'label2').data)
        .map(([name, values]) => ({
          fuid: name,
          ...values,
        }))
        .filter(d => d.fuid !== 'UNEXPLAINED' && !d.fuid.startsWith('Cash') && !d.fuid.startsWith('TRANSACTION FEES'))
      const sortedData = dataArray.sort((a, b) => a.excess_return - b.excess_return)
      const bottom5 = sortedData.slice(0, 5)
      const top5 = sortedData
        .slice()
        .sort((a, b) => b.excess_return - a.excess_return)
        .slice(0, 5)
      return {
        bottom5: bottom5.map(e => [
          e.fuid,
          window.format('.2%')(e.excess_return),
          window.format('.2%')(e.fund_contribution),
          window.format('.2%')(e.last_fund_weight - e.last_benchmark_weight),
        ]),
        top5: top5.map(e => [
          e.fuid,
          window.format('.2%')(e.excess_return),
          window.format('.2%')(e.fund_contribution),
          window.format('.2%')(e.last_fund_weight - e.last_benchmark_weight),
        ]),
      }
    },

    totalsAllocationData() {
      return dimension => {
        if (!this.allocationData || this.allocationData.length === 0) return []
        const dimData = {}
        this.allocationData.forEach(a => {
          if (!dimData[a.axis[dimension]])
            dimData[a.axis[dimension]] = {
              weight_fund: 0,
              weight_benchmark: 0,
            }
          dimData[a.axis[dimension]].weight_fund += a.weight_fund || 0
          dimData[a.axis[dimension]].weight_benchmark += a.weight_benchmark || 0
        })
        return dimData
      }
    },
    totalsAttributionData() {
      return dimension => {
        if (!this.attributionData || this.attributionData.length === 0) return []
        const obj = this.attributionData.find(d => d.dimension === dimension)
        if (!obj) return []
        const totals = Object.values(obj.data).reduce((acc, cur) => {
          Object.keys(cur).forEach(key => {
            if (!acc[key]) acc[key] = 0
            acc[key] += cur[key]
          })
          return acc
        }, {})
        return totals
      }
    },
  },
}
</script>

<template lang="pug">
template(v-if="loaded")
  .factsheet-report.pdf-page.grid.attribution(v-for="dimension in $root.userflow.dimensions")
    pdf-header
    h2(style="margin: 10px auto;justify-content: center;") {{ t.attribution_report }} {{ t.by }} {{ t[dimension] || dimension }}
    .kpis(v-if="excessReturnData")
      .pdf-kpi
        .fund
          .value(v-html="unit(window.format('+.2%')(excessReturnData.performance))")
          .label(v-html="t.gross_return.replace(' return', '<br>return')")
      .pdf-kpi
        .fund.grey
          .value(v-html="unit(window.format('+.2%')(excessReturnData.performance_benchmark))")
          .label(v-html="t.benchmark_return.replace(' return', '<br>return')")
      .pdf-kpi
        .fund.performance_ytd
          .value(v-html="unit(window.format('+.2%')(excessReturnData.excess_return))")
          .label(v-html="t.excess_return.replace(' return', '<br>return')")
      .pdf-kpi
        .fund.allocation_effect
          .value(v-html="unit(window.format('+.2%')(totalsAttributionData(dimension).allocation_effect))")
          .label(v-html="t.allocation_effect")
      .pdf-kpi
        .fund.selection_effect
          .value(v-html="unit(window.format('+.2%')(totalsAttributionData(dimension).selection_effect))")
          .label(v-html="t.selection_effect.replace(' + ', '<br>+ ')")
    .row.metric-diff
      block(title="relative_allocation" type="bar" :data="totalsAllocationData(dimension).map(d => ({ relative_allocation: d.weight_fund - d.weight_benchmark }))" :metadata="{ dimension, max: 15, format: '+.2%' }")
    .row
      block(type="bar" :data="effectData[dimension]" :title="'attribution' + ',by,' + dimension" :metadata="{ dimension, max: 15, format: '+.2%' }")
    .row
      block(title="attribution_trends" type="plot-line" :data="this.allAtttributionHistory[dimension]" :metadata="{ format: '+.2%' }")
    pdf-text.disclaimer_attrib(data="Disclaimer_Attribution")
  .factsheet-report.pdf-page.grid
    pdf-header.indicators
    .row.indicators
      brick.profile_indicators(title="top_contributors" type="pdf-table" :data="this.bestContributors" :metadata="{ format: '.2%' }")
      brick.profile_indicators(title="bottom_contributors" type="pdf-table" :data="this.worstContributors" :metadata="{ format: '.2%' }")
    .row.indicators
      brick.profile_indicators(title="top_relative_weights" type="pdf-table" :data="this.bestContributorsWeightDiff" :metadata="{ format: '.2%' }")
      brick.profile_indicators(title="bottom_relative_weights" type="pdf-table" :data="this.worstContributorsWeightDiff" :metadata="{ format: '.2%' }")
    .row.indicators
      brick.profile_indicators(title="top_excess_return" type="pdf-table" :data="[['name', 'excess_return', 'contribution', 'active_weight']].concat(top_single_attribution.top5)")
      brick.profile_indicators(title="bottom_excess_return" type="pdf-table" :data="[['name', 'excess_return', 'contribution', 'active_weight']].concat(top_single_attribution.bottom5)")
    pdf-text.disclaimer_attrib(data="Disclaimer_Attribution")
</template>

<style scoped>
.pdf * {
  font-family: HelveticaNeue;
  letter-spacing: 0 !important;
}

.pdf-page::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><path d="M0 0v100C0 44.772 44.77 0 100 0H0z" fill="%2344AF65" fill-rule="evenodd"/></svg>');
}

.pdf .block h2::after {
  width: 10px;
  height: 10px;
  margin: 0 -16px 0 6px;
  background: var(--secondary);
  content: '';
}

.pdf header {
  border-bottom: 5px solid var(--tertiary) !important;
}

.pdf header * {
  font-family: Merlo_Regular;
}

.pdf .legend {
  margin: -15px 0 10px auto;
  justify-content: flex-end;
}

.pdf .plottable,
.pdf .content .bar {
  min-height: 180px;
  height: fit-content;
  max-height: 340px;
}

.bar .content .rect.allocation_effect {
  background: var(--cat9);
}

.bar .content .rect.selection_effect {
  background: var(--cat10);
}

.bar .content .total {
  font: var(--p3);
  font-size: 9px;
  color: #687d85;
  fill-opacity: 0.8;
}

.pdf .attribution > .row {
  margin-bottom: 60px;
}

.pdf .block-bar .legend {
  height: 12px;
}

.pdf .pdf-page {
  padding: 56px !important;
  font-size: 11px;
}

.pdf h2 {
  font-size: 20px;
}

.pdf .row .column {
  margin: 10px;
}

.pdf .fund .value {
  padding: 6px 0;
}

.pdf .orientation {
  margin: 0;
  font-size: inherit;
  font-weight: 600;
}

.pdf-page:first-child > .row > .column:first-child {
  min-width: 32%;
  max-width: 32%;
  margin-right: 0;
  padding-right: 10px;
  border-color: #ffde14;
}

.pdf .block > h2 {
  font-size: 12px !important;
  text-transform: inherit;
}

h2,
.legend {
  margin-bottom: 4px;
}

.pdf [class*='block-plot'] .content {
  margin: 0;
}

.pdf-table .cell {
  padding: 4px 2px;
}

[class*='block-main'] .pdf-table .cell {
  max-width: 82px;
  padding: 0;
  border: none;
}

.row.indicators {
  margin-top: 40px !important;
}

.pdf-table .line .cell > div {
  text-align: left;
}

.profile_indicators .pdf-table .cell:nth-child(n + 2) {
  max-width: 80px !important;
}

.profile_indicators .pdf-table .cell:nth-child(n + 2) div {
  text-align: right;
}

.profile_indicators .pdf-table .line:nth-child(odd) {
  background: #eee;
}

.indicators {
  margin-top: 10px;
}

.disclaimer_attrib {
  position: absolute;
  bottom: 20px;
  width: 85%;
}

.pdf .kpis {
  display: flex;
  margin: 10px 0;
  font-size: 9px;
  text-align: center;
  justify-content: center;
  line-height: 1;
}

.pdf .kpis .pdf-kpi {
  max-width: 70px;
  min-height: 60px;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 1px;
  white-space: pre-wrap;
}

.pdf .kpis .pdf-kpi .fund {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 4px;
  background: #d8d9da;
}

.pdf .kpis .pdf-kpi .fund {
  background: var(--highlight);
}

.pdf .kpis .pdf-kpi .benchmark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  background: #eee;
}

.pdf .kpis .pdf-kpi {
  min-width: 70px;
}

.pdf .kpis .benchmark .number {
  font-size: 9px;
  font-weight: 400;
}

.pdf .value .number {
  font-size: 18px;
}

.pdf .value .unit {
  font-size: 9px;
}

.pdf .top_kpi .pdf-kpi {
  max-width: 32%;
}

.pdf .kpis .performance_ytd.fund {
  background: var(--yellow2);
}

.pdf .kpis .performance_ytd.benchmark,
.pdf .kpis .performance_1m.benchmark {
  background: var(--yellow3);
}

.pdf .kpis .volatility_1y.fund {
  background: var(--cat9);
}

.pdf .kpis .pdf-kpi .fund.grey {
  background: var(--cat9);
}

.pdf .kpis .pdf-kpi .fund.allocation_effect {
  background: var(--yellow2);
}

.pdf .kpis .pdf-kpi .fund.selection_effect {
  background: var(--yellow2);
}

.watermark:not(.other) {
  display: block;
}

line.baseline {
  opacity: 0.5 !important;
}

.component.axis.x-axis.category-axis text {
  font-size: 7px !important;
}
</style>

<style>
/* for some reason the below css only works if not scoped */
.factsheet-report .legend {
  margin: -15px 0 10px auto !important;
  justify-content: flex-end;
}

.factsheet-report button,
.factsheet-report .legend .relative_allocation,
.factsheet-report .profile_indicators .legend,
.factsheet-report .screen-factsheet .block > h2::after {
  display: none !important;
}

.factsheet-report .row.indicators .plot-block {
  min-height: 0 !important;
}

.factsheet-report .profile_indicators .pdf-table .line:not(:first-child) .cell:first-child {
  text-transform: uppercase;
  font-weight: 600;
  text-align: left;
}

.factsheet-report .profile_indicators .pdf-table .line:first-child {
  background: white !important;
  border-bottom: 1px solid #fd4;
}
</style>
